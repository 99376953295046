import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
	selector: 'support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.css']
})

export class SupportComponent {
	form: FormGroup;

	constructor(private fb: FormBuilder) {
		this.createForm();
	}

	createForm() {
	    this.form = this.fb.group({
	      name: ['', Validators.required],
	      email: ['', Validators.required],
	      message: ['', Validators.required],
	    });
  	}

  	onSubmit() {
    	const {name, email, message} = this.form.value;
    	const date = Date();
    	const html = `
      		<div>From: ${name}</div>
      		<div>Email: <a href="mailto:${email}">${email}</a></div>
      		<div>Date: ${date}</div>
      		<div>Message: ${message}</div>
    	`;
    	
    	let formRequest = { name, email, message, date, html };
    	// this.af.database.list('/messages').push(formRequest);
    	// this.form.reset();
  }
}
