import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { appRoutes } from './config/router';

import { AppComponent } from './app.component';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';

import { HomeModule } from './pages/home/home.module';
import { FeedbackModule } from './pages/feedback/feedback.module';
import { NotFoundModule } from './pages/not-found/not-found.module';
import { PrivacyModule } from './pages/privacy/privacy.module';
import { SupportModule } from './pages/support/support.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
  	RouterModule.forRoot(
  		appRoutes
      // { enableTracing: true }
	  ),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,    
    HeaderModule,
    FooterModule,
    HomeModule,
    FeedbackModule,
    NotFoundModule,
    PrivacyModule,
    SupportModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
