import { Component } from '@angular/core';

@Component({
	selector: 'footer-component',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})

export class FooterComponent {
	year: Number = new Date().getFullYear();
	getAppUri: string = "https://itunes.apple.com/us/app/units-converter-pro/id1371320395?ls=1&mt=8";

	getAPP() {
		window.open(this.getAppUri);
	}
}