import { Component } from '@angular/core';

@Component({
	selector: 'privacy',
	templateUrl: './privacy.component.html',
	styleUrls: ['./privacy.component.css']
})

export class PrivacyComponent {
}
