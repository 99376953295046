import { Routes } from '@angular/router';

import { HomeComponent } from '../pages/home/home.component';
import { FeedbackComponent } from '../pages/feedback/feedback.component';
import { PrivacyComponent } from '../pages/privacy/privacy.component';
import { SupportComponent } from '../pages/support/support.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';

export const appRoutes: Routes = [	
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'feedback',
		component: FeedbackComponent
	},
	{
		path: 'privacy',
		component: PrivacyComponent
	},
	{
		path: 'support',
		component: SupportComponent
	},
	{
		path: '*',
		component: NotFoundComponent
	}
];