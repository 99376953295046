import { Component } from '@angular/core';

@Component({
	selector: 'feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.css']
})

export class FeedbackComponent {
}
